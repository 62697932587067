


















import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokAwardGrid } from '../../types';
import { getAwards } from '../../store';

@Component
export default class AwardGrid extends Vue {
  @Prop() blok!: BlokAwardGrid;
  get uuids (): string[] {
    return this.blok.mode === 'selected' ? this.blok.selected_awards || [] : getAwards(this.$store).allUuids;
  }
  // -> GSAP animation
  $refs: { awards: HTMLElement, items: HTMLElement };
  animate (): void {
    this.$gsap.to(this.$refs.items, {
      scrollTrigger: {
        trigger: () => this.$refs.awards,
        start: 'bottom bottom-=50px'
      },
      opacity: 1,
      duration: 0.6,
      ease: 'power1.out',
      stagger: {
        each: 0.2
      }
    });
  }
  mounted () {
    if (!process.browser) { return; }
    this.$gsap.set(this.$refs.items, {
      opacity: 0
    });
    this.animate();
  }
}
