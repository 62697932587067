














import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { ContentEntityAwards } from '../../../types';
import { getAwards } from '../../../store';
import MediaHandler from '@/components/utilities/media/MediaHandler';

@Component({
  components: { MediaHandler }
})
export default class AwardTeaser extends Vue {
  @Prop() uuid!: string;
  get award (): ContentEntityAwards|false {
    return getAwards(this.$store).dataByUuid(this.uuid);
  }
}
